import { IItem, ILowStockItem } from "interfaces/graphql";
import React, { useState } from "react";

const SelectedStockItemContext = React.createContext<{
  selectedStockItem: IItem | null;
  setSelectedStockItem: React.Dispatch<React.SetStateAction<IItem | null>>;
}>({
  selectedStockItem: null,
  setSelectedStockItem: () => {},
});

const SelectedStockItemProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedStockItem, setSelectedStockItem] = useState<IItem | null>(
    null
  );

  return (
    <SelectedStockItemContext.Provider
      value={{ selectedStockItem, setSelectedStockItem }}
    >
      {children}
    </SelectedStockItemContext.Provider>
  );
};

export { SelectedStockItemContext, SelectedStockItemProvider };
