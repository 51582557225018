import { Box } from "@mui/material";
import { defaultImageRemoteUrl } from "modules/Stock/constants";
import itemIcon from "assets/illustrations/item-icon.svg";

interface IImageTableRowItemProps {
  image: string;
}

const ImageTableRowItem = ({ image }: IImageTableRowItemProps) => {
  const imageUrl = image === defaultImageRemoteUrl ? itemIcon : image;
  return (
    <Box
      sx={{
        // width: { xs: "30vw", sm: "20vw", md: "10vw", lg: "100px" },
        // height: { xs: "30vw", sm: "20vw", md: "10vw", lg: "100px" },
        width: "50px",
        height: "50px",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <img
        src={imageUrl}
        alt="Product"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </Box>
  );
};

export default ImageTableRowItem;
