import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import { ISearchableItem, useSearchStockLazyQuery } from "interfaces/graphql";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loader from "components/Loader/Loader";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "utils/useDebounce";
import useScreenSize from "hooks/useScreenSize/useScreenSize";

const SearchBox = () => {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState(searchParams.get("text") || "");

  const debouncedInput = useDebounce(inputValue, 500);
  const [showDropdown, setShowDropdown] = useState(false);
  const [results, setResults] = useState<ISearchableItem[]>([]);
  const [fetchStock, { data, loading }] = useSearchStockLazyQuery();

  // 🟢 Debounced Search - Updates URL while typing
  useEffect(() => {
    if (debouncedInput.trim()) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("text", debouncedInput);
        return newParams;
      });

      fetchStock({ variables: { nameTerm: debouncedInput } });
      setShowDropdown(true);
    } else {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("text");
        return newParams;
      });
      setShowDropdown(false);
      setResults([]);
    }
  }, [debouncedInput]);

  // 🟢 Update results when search completes
  useEffect(() => {
    if (data?.searchStock) {
      setResults(data?.searchStock);
    }
  }, [data]);

  // 🟢 Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 🔵 Handle Search Submission (Hitting Enter)
  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowDropdown(false);

    if (isMobile) {
      navigate(`/mobile/stock-search?text=${encodeURIComponent(inputValue)}`);
      return;
    }

    navigate(`/stock/search?text=${encodeURIComponent(inputValue)}`);
  };

  const handleListItemClick = (itemId: string) => {
    setShowDropdown(false);
    navigate(`/stock/item/${itemId}`);
  };

  return (
    <Box ref={searchRef} sx={{ position: "relative" }}>
      {/* 🔍 Search Input */}
      <form onSubmit={handleSearchSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search items..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={() => setShowDropdown(results.length > 0)}
          InputProps={{
            sx: {
              borderRadius: showDropdown ? "12px 12px 0 0" : "24px",
              backgroundColor: "#f0f0f0",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#666" }} />
              </InputAdornment>
            ),
          }}
        />
      </form>

      {/* 🔽 Search Suggestions (Dropdown) */}
      {showDropdown && (
        <Paper
          sx={{
            position: "absolute",
            width: "100%",
            borderRadius: "0 0 12px 12px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            zIndex: 10,
          }}
        >
          {loading && <Loader />}
          <List>
            {results.length > 0 ? (
              results.map((result) => (
                <ListItem
                  key={result.id}
                  onClick={() => handleListItemClick(result.id)}
                  sx={{
                    cursor: "pointer",
                    transition: "background-color 0.2s ease-in-out",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                  }}
                >
                  <ListItemText primary={result.name} />
                </ListItem>
              ))
            ) : (
              <ListItem>No results found</ListItem>
            )}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default SearchBox;
