import { Grid } from "@mui/material";
import SearchBox from "modules/Stock/components/SearchBox/SearchBox";

const StockToolbar = () => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <SearchBox />
      </Grid>
    </Grid>
  );
};

export default StockToolbar;
